<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                  <!-- <el-button v-if="auth.save"
                    icon="el-icon-plus"
                    type="primary"
                    @click.stop="handleAddBtn"
                >新增</el-button> -->
                <el-row style="margin-bottom:15px;display: flex;">
                    <el-form class="private" :inline="true" label-suffix=":"
                        label-width="auto" @submit.native.prevent>
                        <el-form-item label="状态">
                            <el-select class='width2'  v-model="search_type" placeholder="请选择">
                                    <el-option v-for="item in searchTypeEnum" :key="item.value" :label="item.name" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-button style="margin-left:24px;" type="primary" @click.stop="handleSubmit">搜索</el-button>
                    <el-button @click.stop="handleClear">清空</el-button>
                    <el-button v-if="auth.export" @click.stop="handleExportBtn">导出</el-button>
                    <el-button style="margin-left:auto;"  @click.stop="handleNavigationBack">返回</el-button>


                </el-row>

                <div style="padding-top:10px;">邮件主题：{{cenEmail.title}} </div>

                <el-table :data="tableData">
                    <el-table-column label="收件邮箱" prop="email"></el-table-column>
                    <el-table-column label="发送时间"  prop="createTime">
                        <template slot-scope="scope">
                            <div v-html="scope.row.createTime"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态" prop="statusName">
                          <!-- <template slot-scope="scope">
                            <div>{{scope.row.status==1?'成功':'失败'}}</div>
                        </template> -->
                    </el-table-column>
                    <!-- <el-table-column label="操作"
                        fixed="right" min-width="120">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="true||auth.save"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >详情</el-button>
                             
                                <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            emailId:'',
            tableData: [],
            cenEmail:'',
            search_value: "",
            search_type: '',
            pageNum: 1,
            pageSize: 10,
            total: 0,

            searchTypeEnum: [
                { name: "成功", value: 1 },
                { name: "失败", value: 0 },
            ],
            auth:{
               delete:false 
            }
        };
    },
    created() {
		this.auth.export      = this.$hasAuthFor("api/cenEmail/exportEmailDetail");
		this.auth.save        = this.$hasAuthFor("api/employee/saveEmp");
        this.auth.delete = this.$hasAuthFor("api/channel/deleteChannel"); //删除

        console.log(this.$route);

        this.emailId = this.$route.query.id
        this.fetchData();

        


    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        // 点击导出
        async handleExportBtn () {
			let loadingRef;
			try {
				await this.$confirm("确定导出吗", "系统提示")
				loadingRef = this.$loading();

                // const data = this.extractParams(true);
				// if (data.search_sys) {
				// 	const sys = this.systemEnum.find(it =>
				// 		it.id === data.search_sys)
				// 	data.search_sys = sys?.sysCode
				// }

                const res = await this.$axios({
                    url: "/api/cenEmail/exportEmailDetail",
                    method: "post",
					data:{
                        status:this.search_type,
                        emailId:this.emailId
                    }
                })

				if (res.code === 2000) {
					// this.$message({
                    //     message: "已加入导出任务，去消息管理的下载通知中查看吧",
                    //     type: "success"
                    // })
					window.open(this.$baseURL + "/" + res.data.pathInfo.path)
					loadingRef && loadingRef.close();
	
				} else if (res.code !== 1003) 
					throw res;

			} catch (reason) {
				if (reason !== "cancel") {
					this.$message({
                        message: reason.msg || "导出失败",
                        type: "error"
                    });
				}
				loadingRef && loadingRef.close();
			}
        },
         // 点击返回
        handleNavigationBack () {
            this.$router.back();
        },
        // 点击 "新增"
		handleAddBtn() {
			this.$router.push({
				path: "/staff/list/add",
				query: {sys_id: this.SP_.search_sys}
			});
		},
        // 详情
        handleDetail(data){
            // 渠道详情

            this.$router.push({
				path: `/customer/channelPhoneList/Detail`,
				query: {id: data.id,channelCode:data.channelCode}
			});

            // 渠道新增 编辑

            // this.$router.push({
			// 	path: `/customer/channelPhoneList/add`,
			// 	query: {id: data.id}
			// });

            // 在线签署渠道协议列表
            // this.$router.push({
			// 	path: `/customer/channelLineList`,
			// 	query: {id: data.id}
			// });

        },
        // 点击表格行操作按钮: 删除
        async handleDelete(data) {
            let loadingRef;
            try {
                await this.$confirm("请先确定该渠道在各业务系统中是否关联客户，删除后会同步删除业务系统中的渠道，您确定要删除么？", "删除渠道提示信息")
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/channel/deleteChannel",
                    method: "post",
                    data: {
                        id: parseInt(data.id)
                    }
                })
                if (res.code === 2000) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    });
                    loadingRef && loadingRef.close();
                    this.fetchData();

                } else if (res.code !== 1003)
                    throw res;

            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "删除失败",
                        type: "warning"
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        handleSubmit() {
            this.pageNum=1
            this.pageSize=10
            this.fetchData();
        },
        handleClear() {
            this.pageNum=1
            this.pageSize=10
            // this.search_value = "";
            this.search_type = '';
            this.fetchData();
        },
        async fetchData() {
            const loading = this.$loading();
            const res = await this.$axios({
                // url: "/api/channel/queryChannelPhoneList",
                url: "/api/cenEmail/queryCenEmailDetailList",
                method: "post",
                data: {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    // search_type: this.search_type,
                    // search_value: this.search_value,
                    status:this.search_type,
                    emailId:this.emailId
                },
            });
            loading.close();
            if (res.code === 2000) {
                this.tableData = res.data.sendEmailDetailList || [];
                this.total = res.data.count || 0;
                this.cenEmail = res.data.cenEmail
            }else{
                this.$message({
                    message: res.msg || "获取数据失败",
                    type: "warning"
                });
            }
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.fetchData();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.fetchData();
        },
    },
};
</script>
<style lang="scss" scoped>
</style>